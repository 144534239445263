import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import {
  CustomLeftArrow,
  CustomRightArrow,
  Responsive,
} from '../CarouseElements'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import 'react-photo-view/dist/react-photo-view.css'
import { ALT_IMAGE } from '@/constants/common'
import { ExperienceType } from '@/constants/generalCarousel'
import { Link } from 'react-router-dom'
import { normalizeItem } from '@/utils/normalizeUrl'
import { Loading } from '@/components/Loading'
interface GeneralCarouselProps {
  data: any
  autoPlaySpeed?: number
  itemsToShow?: {
    xlarge?: number
    desktop?: number
    tablet?: number
    mobile?: number
  }
  imageGallery?: boolean
  type?: string
  eventTrigger?: (item) => void
}
const GeneralCarousel = ({
  data = [],
  autoPlaySpeed = 0,
  itemsToShow = {
    xlarge: 3,
    desktop: 3,
    tablet: 2,
    mobile: 1.5,
  },
  imageGallery = false,
  type,
  eventTrigger = () => {},
}: GeneralCarouselProps) => {
  return (
    <div className="relative">
      <PhotoProvider maskOpacity={0.5}>
        {Array.isArray(data) && !!data.length ? (
          <Carousel
            className=" text-white static"
            responsive={Responsive(itemsToShow)}
            infinite={true}
            ssr={true}
            focusOnSelect={false}
            removeArrowOnDeviceType={['mobile', 'tablet']}
            autoPlay={!!autoPlaySpeed}
            autoPlaySpeed={autoPlaySpeed}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
          >
            {data.map((item, index) => {
              const imgSrc = item?.image || item || ALT_IMAGE
              const itemName =
                `${item?.name} image` || `Custom image ${index + 1}`
              return (
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0)
                    eventTrigger(item)
                  }}
                  tabIndex={-1}
                  key={index}
                  to={imageGallery ? '' : normalizeItem({ ...item, type })}
                >
                  <div
                    key={index}
                    className={`${
                      imageGallery
                        ? 'h-64 md:h-[35vh] lg:h-[50vh]'
                        : 'h-64 md:h-80'
                    } cursor-pointer `}
                  >
                    {imageGallery ? (
                      <PhotoView src={imgSrc}>
                        <img
                          className="object-cover w-full h-full rounded-2xl p-2"
                          src={imgSrc}
                          alt={item?.imageAlt || itemName}
                          title={item?.imageTitle || itemName}
                          loading="lazy"
                        />
                      </PhotoView>
                    ) : (
                      <img
                        className={`object-cover w-full h-full rounded-2xl p-2 ${
                          type === ExperienceType.CATEGORIES
                            ? 'brightness-50'
                            : ''
                        }`}
                        src={imgSrc}
                        alt={itemName}
                      />
                    )}
                    {type !== ExperienceType.CATEGORIES ? (
                      <div className="absolute bottom-8 left-8 right-4 px">
                        <h2 className="text-2xl select-none">{item?.name}</h2>
                      </div>
                    ) : (
                      <div className="absolute top-24 md:top-32 left-0 w-full">
                        <img
                          src={item?.icon}
                          alt={item?.name}
                          id="whiteSvg"
                          className="w-full h-12 mb-2"
                          loading="lazy"
                        />
                        <h2 className="text-2xl select-none text-center">
                          {item?.name}
                        </h2>
                      </div>
                    )}
                  </div>
                </Link>
              )
            })}
          </Carousel>
        ) : (
          <div
            className={`${
              imageGallery ? 'h-64 md:h-[35vh] lg:h-[50vh]' : 'h-64 md:h-80'
            }`}
          >
            <Loading fullHeight={false} />
          </div>
        )}
      </PhotoProvider>
    </div>
  )
}
export default GeneralCarousel
