import React from 'react'
import { useTranslation } from 'react-i18next'
import DetailSimpleBullet from './DetailSimpleBullet'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { EXPERIENCE_CANCELLATION_POLICY } from '@/constants/experience'
import { Divider } from '@nextui-org/react'

const ExperienceCancellationPolicies = () => {
  const { t } = useTranslation()
  const {
    state: { experience },
  } = useGotuuriContext()
  return (
    <>
      <Divider className="bg-liteGray my-6" />
      <div className="flex flex-col lg:flex-row">
        <h3 className="text-2xl mb-4 w-full lg:w-60 lg:mb-0 h-min">
          {t('experience.cancellationPolicies')}
        </h3>
        <section className="flex-1">
          <ul className={`w-full flex flex-col flex-wrap overflow-hidden`}>
            <DetailSimpleBullet
              className="!mb-0"
              name={t(
                EXPERIENCE_CANCELLATION_POLICY[
                  experience?.cancellation_policy || 1
                ]
              )}
            />
          </ul>
        </section>
      </div>
    </>
  )
}

export default ExperienceCancellationPolicies
