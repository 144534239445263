import React, { useEffect, useRef, useState } from 'react'
import 'react-multi-carousel/lib/styles.css'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { useTranslation } from 'react-i18next'
import { userPreferredLanguage } from '@/utils/userPreferredLanguage'
import { useNavigate } from 'react-router-dom'
import { SignInUp } from '@/components/SignInUp'
import { ExperienceCard } from '@/components/ExperienceCard'
import { Loading } from '@/components/Loading'
import { ArrowLeftIcon, ArrowRightIcon } from '@/assets/icons'
import { useKeenSlider } from 'keen-slider/react'
import { useIsMobile } from '@/utils/mobile'

const SimilarExperiencesCarousel = ({ title }: { title?: React.ReactNode }) => {
  const {
    hooks: { setFavoriteExperience, getFilterExperiences },
    state: { user, experiences: allExperiences, experience },
  } = useGotuuriContext()
  const firstRender = useRef(true)
  const countRender = useRef(0)
  const isMobile = useIsMobile()

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      breakpoints: {
        '(min-width: 1px)': {
          slides: { perView: 2, spacing: 6 },
        },
        '(min-width: 768px)': {
          slides: { perView: 3, spacing: 12 },
        },
        '(min-width: 1024px)': {
          slides: { perView: 4, spacing: 12 },
        },
      },
      slides: { perView: isMobile ? 2 : 4, spacing: 12 },
      initial: 0,
    },
    [
      (slider) => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 4000)
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on('dragStarted', clearNextTimeout)
        slider.on('animationEnded', nextTimeout)
        slider.on('updated', nextTimeout)
      },
    ]
  )

  const getExperiences = (searchBy: 'simple' | 'advanced') => {
    if (countRender.current > 2) return
    if (!experience) return

    firstRender.current = false
    countRender.current++
    if (searchBy == 'simple') {
      getFilterExperiences({
        page: 1,
        newSearch: true,
        data: {
          city: experience?.city?.id,
        },
      })
    }
    if (searchBy == 'advanced') {
      let rangePrice

      if (experience.price_package) {
        rangePrice = [experience?.price_package, experience?.price_package * 2]
      } else {
        rangePrice = [experience?.price_adults, experience?.price_adults * 2]
      }

      getFilterExperiences({
        page: 1,
        newSearch: true,
        data: {
          country: experience?.country?.id,
          categories:
            experience?.categories?.map((category) => category.id) || [],
          rangePrice,
        },
      })
    }
  }
  let experiences = allExperiences?.data.filter(
    (item) => item.id != experience?.id
  )

  useEffect(() => {
    getExperiences('advanced')
  }, [experience])

  useEffect(() => {
    if (firstRender.current) return
    if (experiences?.length < 4) {
      getExperiences('simple')
    }
  }, [experiences])

  const { t } = useTranslation()

  const defaultLang = userPreferredLanguage()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  const addFavorite = (e, experience) => {
    e.stopPropagation()
    !!user
      ? setFavoriteExperience({
          experience: experience,
          userId: user?.id,
          type: 'carousel',
        })
      : setIsOpen(true)
  }

  if (!experiences) {
    return <></>
  }
  return (
    <>
      {title && title}
      <div className={`navigation-wrapper relative`}>
        <div ref={sliderRef} className="keen-slider">
          {!!experiences?.length ? (
            experiences?.map((localExperience, index) => (
              <div
                key={index}
                className="keen-slider__slide min-w-fit max-w-fit py-2 px-1 "
              >
                <ExperienceCard
                  experience={localExperience}
                  navigate={navigate}
                  defaultLang={defaultLang}
                  addFavorite={addFavorite}
                  screenName={`Experience Page ${experience.name}`}
                  t={t}
                />
              </div>
            ))
          ) : (
            <div className="h-64 md:h-80 w-full">
              <Loading fullHeight={false} />
            </div>
          )}
        </div>
        <ArrowLeftIcon
          onClick={(e: any) => {
            e.stopPropagation() ||
              (instanceRef?.current.next && instanceRef.current?.prev())
          }}
          className="h-12 w-12 absolute -left-4 top-[43%] text-lightGray rounded-full shadow-md p-3.5 !bg-white z-10 cursor-pointer hidden md:block"
        />
        <ArrowRightIcon
          onClick={(e: any) =>
            e.stopPropagation() ||
            (instanceRef?.current.next && instanceRef.current?.next())
          }
          className="h-12 w-12 absolute -right-4 top-[43%] text-lightGray rounded-full shadow-md p-3.5 !bg-white z-10 cursor-pointer hidden md:block"
        />
      </div>
      {isOpen && <SignInUp open={isOpen} onClose={() => setIsOpen(false)} />}
    </>
  )
}

export default SimilarExperiencesCarousel
