import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from '@nextui-org/react'
import { BookingCard } from '@/components/BookingCard'
import { CustomMap } from '@/components/CustomMap'
import { useIsMobile } from '@/utils/mobile'
import { ExperiencePhotosCarousel } from '@/components/Carousels/ExperiencePhotosCarousel'

import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { useParams } from 'react-router-dom'
import { ExperienceReviewCard } from '@/components/ExperienceReviewCard'
import { ScrollToTopButton } from '@/components/ScrollToTopButton'
import {
  AdditionalQuestions,
  ChubbDisclosure,
  ExperienceCancellationPolicies,
  ExperienceDetails,
  DisclosureWhyUs,
  ExperienceRecommendation,
  ExperienceRequirements,
  ExperienceTitle,
  ExperienceWhatIncludes,
  NotFoundExperiencePage,
  SectionsNavbar,
  ReadMore,
  LayoutExperienceSkeleton,
} from '@/components/ExperiencePage'
import { ExperienceBreadCrumbs } from '@/components/ExperiencePage/ExperienceBreadCrumbs'
import { track } from '@/utils/analytics'
import { CleverEvents } from '@/utils/events'
import { SimilarExperiencesCarousel } from '@/components/NewCarousels'

const ExperiencePage = () => {
  const { t } = useTranslation()
  const { id, experienceLang } = useParams()
  const isMobile = useIsMobile()

  const {
    hooks: {
      getExperienceDetail,
      postScheduleBycompany,
      getAvailableDates,
      getDisableSchedules,
    },
    state: { experience, locale, loadingExperienceDetail, notFoundExperience },
  } = useGotuuriContext()

  useEffect(() => {
    if (!!!localStorage.getItem('locale') && window != undefined) {
      localStorage.setItem('locale', experienceLang)
      window.dispatchEvent(new Event('storage'))
    }
  }, [experienceLang, locale])

  useEffect(() => {
    if (experience?.id === id) return
    getExperienceDetail(id)
    getAvailableDates(id)
    getDisableSchedules(id)
    postScheduleBycompany({ experience: id })
  }, [id])

  if (loadingExperienceDetail) {
    return (
      <div className="mb-32">
        <LayoutExperienceSkeleton />
      </div>
    )
  }

  if (notFoundExperience) {
    return (
      <div className="w-full layout-padding">
        <NotFoundExperiencePage />
      </div>
    )
  }

  return (
    <div className="mb-16 mt-8 md:mt-0">
      {!isMobile && (
        <div className="layout-padding">
          <ExperienceTitle />
        </div>
      )}
      {isMobile && (
        <>
          <ExperienceBreadCrumbs
            experience={experience}
            t={t}
            trackFunction={() => {
              track('Experience Location View', {
                experienceName: experience?.name,
                experienceId: experience?.id,
              })
              CleverEvents.DestinationView({
                name: experience?.destination.name,
                id: experience?.destination.id,
                screen: 'Experience',
              })
            }}
          />
          <ExperiencePhotosCarousel
            photos={experience?.photos_url || []}
            experience={experience}
          />
        </>
      )}
      <ScrollToTopButton />
      <div className="flex gap-4 mb-8 layout-padding">
        <section className="w-full md:w-7/12 lg:w-4/6 xl:w-[70%]">
          {!isMobile && (
            <ExperiencePhotosCarousel
              photos={experience?.photos_url || []}
              experience={experience}
            />
          )}
          {isMobile && <ExperienceTitle />}
          <SectionsNavbar />
          <ReadMore text={experience?.description ?? ''} />
          <ExperienceDetails />
          <ExperienceWhatIncludes />
          <ExperienceRecommendation />
          <ExperienceRequirements />
          <ExperienceCancellationPolicies />
          <ChubbDisclosure />
          <Divider className="bg-liteGray mt-4 mb-8 md:my-10" />
          <div className="flex flex-col lg:flex-row gap-4">
            <DisclosureWhyUs className="lg:w-2/4" />
            <AdditionalQuestions className="lg:w-2/4" />
          </div>
          <Divider className="bg-liteGray mb-6 mt-10" />
          <h3 id="location" className="text-2xl mb-4">
            {t('experience.location')}
          </h3>
          <div className=" w-full h-80">
            {!!experience && (
              <CustomMap
                readOnly={true}
                initialPosition={{
                  lat: experience?.latitude,
                  lng: experience?.longitude,
                }}
              />
            )}
          </div>
        </section>
        {!isMobile && <BookingCard />}
      </div>
      {isMobile && <BookingCard />}
      <div className="layout-padding select-none">
        <SimilarExperiencesCarousel
          title={
            <h3 id="simialar-experiences" className="text-2xl mb-6 mt-8">
              {t('experience.similarActivities')}
            </h3>
          }
        />
        <div id="reviews"></div>
        {!!experience?.reviews?.length ? (
          <>
            <div className="flex flex-row my-8">
              <h3 className="text-2xl">
                {t('experience.customerReview')}
                <span className={`text-[#FFBD00] select-none ml-3`}>★</span>
              </h3>
              <p className="text-base my-auto ml-1">
                {(experience['rating'] || 0).toFixed(1)}
              </p>
            </div>
            <ExperienceReviewCard experienceReview={experience?.reviews} />
          </>
        ) : null}
      </div>
    </div>
  )
}
export default ExperiencePage
