import { EXPERIENCE_DETAIL_TYPES } from '@/constants/experience'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import DetailSimpleBullet from './DetailSimpleBullet'
import ShowMore from './ShowMore'
import { CheckIcon, XIcon } from '@/assets/icons'
import { Divider } from '@nextui-org/react'

const MAX_ITEMS = 10

const ExperienceWhatIncludes = () => {
  const { t } = useTranslation()
  const {
    state: { experience },
  } = useGotuuriContext()

  const { not_included, included } = EXPERIENCE_DETAIL_TYPES

  const allIncludes = experience?.details
    ?.filter(({ type }) => type === not_included || type === included)
    ?.sort(({ type: a }, { type: b }) => a.localeCompare(b))

  const [showAll, setShowAll] = useState(false)

  const visibleDetails = showAll
    ? allIncludes
    : allIncludes?.slice(0, MAX_ITEMS)


  if (allIncludes?.length === 0 || !allIncludes) {
    return null
  }

  return (
    <>
      <Divider className="bg-liteGray my-6" />
      <div className="flex flex-col lg:flex-row">
        <h3 className="text-2xl mb-4 w-full lg:w-60 lg:mb-0 h-min">
          {t('experience.includeTitle')}
        </h3>
        <section className="flex-1">
          <ul
            className={`w-full flex flex-col ${showAll ? 'lg:max-h-[450px]' : 'lg:max-h-[270px]'} flex-wrap overflow-hidden`}
          >
            {visibleDetails?.map((detail) => {
              const include = detail.type === included
              return (
                <DetailSimpleBullet
                  key={detail.id}
                  name={detail.name}
                  icon={
                    include ? (
                      <CheckIcon className="text-primary w-4" />
                    ) : (
                      <XIcon className="text-red-500 w-4" />
                    )
                  }
                  className="lg:w-1/2"
                />
              )
            })}
          </ul>

          {allIncludes?.length > MAX_ITEMS && (
            <ShowMore showAll={showAll} setShowAll={setShowAll} />
          )}
        </section>
      </div>
    </>
  )
}

export default ExperienceWhatIncludes
