import { Accordion } from '@/components/Accordion'
import { ArrowBlackImg } from '@/components/ArrowBlackImg'
import { Divider, Switch, cn } from '@nextui-org/react'
import AdditionalServicesIcon from '@/assets/svgs/additionalServicesIcon.svg'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { track } from '@/utils/analytics'

interface AdditionalProductsRow {
  additionalProducts: Array<any>
  setAdditionalAmount: (data: any) => void
  setTotalProducts: (data: any) => void
  totalProducts: Array<any>
  bookingData: any
}

const AdditionalProductsRow = ({
  additionalProducts,
  setAdditionalAmount,
  setTotalProducts,
  totalProducts,
  bookingData,
}: AdditionalProductsRow) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (!!bookingData && !!bookingData?.totalProducts) {
      setTotalProducts(bookingData?.totalProducts)
      setAdditionalAmount(bookingData?.additionalAmount)
    }
  }, [])

  return (
    <>
      <Accordion
        header={(isOpen) => (
          <div
            className="flex flex-row w-full select-none"
            role="button"
            onClick={() => track('Experience Add-ons View')}
          >
            <img
              className="h-5 w-5 mr-4 my-auto"
              src={AdditionalServicesIcon}
              alt="calendar svg"
            />
            <h3 className="text-base text-black text-center truncate">
              {t('bookingCard.addOns')}
            </h3>
            <ArrowBlackImg
              className={`ml-auto ${isOpen ? 'rotate-180' : ''}`}
            />
          </div>
        )}
      >
        {!!additionalProducts &&
          !!additionalProducts?.length &&
          additionalProducts?.map((additionalProduct, index) => {
            return (
              <div
                key={additionalProduct?.id}
                className="flex flex-row w-full select-none mt-6 mb-4 items-center overflow-hidden"
              >
                <img
                  className="h-5 w-5 mr-4 my-auto"
                  src={additionalProduct?.additional_item?.icon_url ?? ''}
                  alt={
                    additionalProduct?.additional_item?.name ?? 'generic image'
                  }
                />
                <h3 className="text-base text-black text-center truncate">
                  {additionalProduct?.additional_item?.name}
                </h3>
                <Switch
                  size="sm"
                  classNames={{
                    wrapper: cn('group-data-[selected=true]:!bg-primary'),
                  }}
                  className="ml-auto -mr-1.5"
                  defaultSelected={
                    !!totalProducts?.find(
                      (product) => product.id === additionalProduct.id
                    ) || false
                  }
                  onValueChange={(isChecked) => {
                    if (isChecked) {
                      setTotalProducts([...totalProducts, additionalProduct])
                    } else {
                      setTotalProducts(
                        totalProducts.filter(
                          (product) => product?.id !== additionalProduct?.id
                        )
                      )
                    }
                  }}
                />
              </div>
            )
          })}
      </Accordion>
      <Divider className="mb-4" />
    </>
  )
}

export default AdditionalProductsRow
