import { amountFormatter } from "./amountFormatter"

export const scrollToElement = (elementId: string) => {
  const element = document.getElementById(elementId)
  if (element) {
    const yOffset = -100
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
    window.scrollTo({ top: y, behavior: 'smooth' })
  }
}

export const getPriceRegularOrstaggered = (experience) => {
  const { price_discount_groups, price_adults, price_package } =
    experience || {}

  if (price_discount_groups?.length > 0) {
    const lastDiscountGroup =
      price_discount_groups[price_discount_groups.length - 1]
    const discount = (price_adults * lastDiscountGroup.discount) / 100
    return amountFormatter(price_adults - discount)
  }

  return amountFormatter(price_adults ?? price_package)
}

export const convertToAmPm = (hour) => {
  if (!hour) return
  let [hours, minutes] = hour?.split(':').map(Number)

  let amPm = ''

  // Determine AM/PM and adjust the hour for 12-hour format
  if (hours === 0) {
    amPm = 'AM'
    hours = 12
  } else if (hours === 12) {
    amPm = 'PM'
  } else if (hours > 12) {
    amPm = 'PM'
    hours -= 12
  } else {
    amPm = 'AM'
  }

  // Return the time in "hh:mm AM/PM" format
  return `${hours?.toString().padStart(2, '0')}:${minutes?.toString().padStart(2, '0')} ${amPm}`
}