import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { CustomLeftArrow, CustomRightArrow } from '../CarouseElements'
import 'react-photo-view/dist/react-photo-view.css'
import { Loading } from '@/components/Loading'
import { userPreferredLanguage } from '@/utils/userPreferredLanguage'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { useTranslation } from 'react-i18next'
import { SignInUp } from '@/components/SignInUp'
import { useIsMobile } from '@/utils/mobile'
import { ExperienceCard } from '@/components/ExperienceCard'

const ExperienceCarousel = ({ textClass }: { textClass?: string }) => {
  const {
    hooks: { setFavoriteExperience },
    state: { user, carousels, loadingCarousels },
  } = useGotuuriContext()

  const { t } = useTranslation()

  const defaultLang = userPreferredLanguage()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const isMobile = useIsMobile()

  const addFavorite = (e, experience) => {
    e.stopPropagation()
    !!user
      ? setFavoriteExperience({
          experience: experience,
          userId: user?.id,
          type: 'carousel',
        })
      : setIsOpen(true)
  }

  if (loadingCarousels) {
    return (
      <div className={'h-72 lg:h-[415px]'}>
        <Loading fullHeight={false} />
      </div>
    )
  }

  if (!!!carousels) {
    return null
  }

  return carousels.map((carousel, index) => {
    return (
      <div key={index} className='relative'>
        <h2
          className={`text-xl md:text-3xl mb-6 mt-12 select-none ${textClass}`}
        >
          {carousel.title}
        </h2>
        <Carousel
          className="static -mr-[11px] md:-mr-[9px] md:ml-1"
          responsive={{
            superLargeDesktop: {
              breakpoint: { max: 4000, min: 1540 },
              items: 4,
            },
            desktop: {
              breakpoint: { max: 1540, min: 1280 },
              items: 4,
            },
            tablet: {
              breakpoint: { max: 1280, min: 768 },
              items: 3,
            },
            mobile: {
              breakpoint: { max: 768, min: 0 },
              items: 2,
            },
          }}
          infinite={true}
          ssr={true}
          focusOnSelect={false}
          removeArrowOnDeviceType={['mobile', 'tablet']}
          autoPlay={true}
          autoPlaySpeed={isMobile ? 3000 : 2000}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
        >
          {!!carousel?.experiences &&
            carousel?.experiences.map((experience, index) => {
              return (
                <div
                  className="my-1 pr-2.5 md:pr-5 pl-0.5 2xl:pl-px"
                  key={index}
                >
                  <ExperienceCard
                    addFavorite={addFavorite}
                    experience={experience}
                    defaultLang={defaultLang}
                    navigate={navigate}
                    screenName={`Carousel-${carousel.title}`}
                    t={t}
                  />
                </div>
              )
            })}
        </Carousel>
        {isOpen && <SignInUp open={isOpen} onClose={() => setIsOpen(false)} />}
      </div>
    )
  })
}

export default ExperienceCarousel
