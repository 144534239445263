import { ExperienceType } from '@/constants/generalCarousel'
import { CleverEvents } from '@/utils/events'
import { getCountryLocaleString } from '@/utils/generalTitle.Util'
import { normalizeItem } from '@/utils/normalizeUrl'
import { Button } from '@nextui-org/react'
import React from 'react'
import { Link } from 'react-router-dom'

export const ExperienceBreadCrumbs = ({ experience, t, trackFunction }) => {
  return (
    <div className="flex gap-3 items-center justify-start mb-3 -mt-5 text-xs ml-4">
      <Link
        to={normalizeItem({
          ...experience?.country,
          type: ExperienceType.COUNTRY,
        })}
        target="_self"
      >
        <Button
          onClick={() =>
            CleverEvents.CountryView({
              id: experience?.country?.id,
              name: experience?.country?.name,
              screen: 'Experience',
            })
          }
          className={`!bg-white text-primary rounded-full gap-0 ps-1 !px-2 pe-2 pt-0 h-6 text-[13px] border-1 border-gray-200 `}
        >
          {t(getCountryLocaleString(experience?.country?.iso_code))}
        </Button>
      </Link>
      {experience?.destination?.name && (
        <Link
          to={normalizeItem({
            id: experience?.destination.id,
            name: experience?.destination.name,
            type: ExperienceType.DESTINATIONS,
          })}
          target="_self"
        >
          <Button
            onClick={trackFunction}
            className={`!bg-white text-primary rounded-full gap-0 ps-1 !px-2 pe-2 pt-0 h-6 text-[13px] border-1 border-gray-200 `}
          >
            {experience?.destination?.name}
          </Button>
        </Link>
      )}
    </div>
  )
}
