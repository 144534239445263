import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { scrollToElement } from '@/utils/common'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const SectionsNavbar = () => {
  const [activeSection, setActiveSection] = useState<string>('description')
  const { t } = useTranslation()
  const {
    state: { experience },
  } = useGotuuriContext()
  const SECTIONS = [
    { id: 'description', text: t('experience.description') },
    { id: 'details', text: t('experience.detailsTitle') },
    { id: 'location', text: t('experience.location') },
    { id: 'simialar-experiences', text: t('experience.similarActivities') },
    !!experience?.reviews?.length && {
      id: 'reviews',
      text: t('experience.customerReview'),
    },
  ]

  const scrollToSection = (section: string) => {
    scrollToElement(section)
    setActiveSection(section)
  }

  const handleScroll = () => {
    let currentSection = 'description'
    SECTIONS.forEach((section) => {
      const element = document.getElementById(section.id)
      if (element) {
        const rect = element.getBoundingClientRect()
        if (
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2
        ) {
          currentSection = section.id
        }
      }
    })

    setActiveSection(currentSection)
  }

  useEffect(() => {
    if (typeof window === 'undefined') return
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <nav className="hidden md:block my-6">
      <ul className="flex justify-start md:gap-4 lg:gap-8 2xl:gap-12">
        {SECTIONS.map((section, index) => (
          <li
            key={index}
            className={`cursor-pointer text-base lg:text-lg transition-all ${activeSection === section.id ? 'text-primary border-b-3 border-primary' : ''}`}
            onClick={() => scrollToSection(section.id)}
          >
            {section.text}
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default SectionsNavbar
